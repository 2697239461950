exports.components = {
  "component---cache-caches-gatsby-plugin-offline-next-app-shell-js": () => import("./../../caches/gatsby-plugin-offline-next/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-next-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-current-openings-js": () => import("./../../../src/pages/careers/current-openings.js" /* webpackChunkName: "component---src-pages-careers-current-openings-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-centralized-crypto-exchange-development-js": () => import("./../../../src/pages/centralized-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-centralized-crypto-exchange-development-js" */),
  "component---src-pages-clone-script-js": () => import("./../../../src/pages/clone-script.js" /* webpackChunkName: "component---src-pages-clone-script-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cryptocurrency-exchange-development-company-js": () => import("./../../../src/pages/cryptocurrency-exchange-development-company.js" /* webpackChunkName: "component---src-pages-cryptocurrency-exchange-development-company-js" */),
  "component---src-pages-cryptocurrency-mlm-software-development-js": () => import("./../../../src/pages/cryptocurrency-mlm-software-development.js" /* webpackChunkName: "component---src-pages-cryptocurrency-mlm-software-development-js" */),
  "component---src-pages-custom-web-development-company-js": () => import("./../../../src/pages/custom-web-development-company.js" /* webpackChunkName: "component---src-pages-custom-web-development-company-js" */),
  "component---src-pages-dao-development-company-js": () => import("./../../../src/pages/dao-development-company.js" /* webpackChunkName: "component---src-pages-dao-development-company-js" */),
  "component---src-pages-decentralized-exchange-development-js": () => import("./../../../src/pages/decentralized-exchange-development.js" /* webpackChunkName: "component---src-pages-decentralized-exchange-development-js" */),
  "component---src-pages-defi-development-company-js": () => import("./../../../src/pages/defi-development-company.js" /* webpackChunkName: "component---src-pages-defi-development-company-js" */),
  "component---src-pages-enterprise-blockchain-development-js": () => import("./../../../src/pages/enterprise-blockchain-development.js" /* webpackChunkName: "component---src-pages-enterprise-blockchain-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matrimony-clone-script-js": () => import("./../../../src/pages/matrimony-clone-script.js" /* webpackChunkName: "component---src-pages-matrimony-clone-script-js" */),
  "component---src-pages-mean-and-mern-full-stack-development-js": () => import("./../../../src/pages/mean-and-mern-full-stack-development.js" /* webpackChunkName: "component---src-pages-mean-and-mern-full-stack-development-js" */),
  "component---src-pages-nft-development-company-js": () => import("./../../../src/pages/nft-development-company.js" /* webpackChunkName: "component---src-pages-nft-development-company-js" */),
  "component---src-pages-nft-marketplace-development-company-js": () => import("./../../../src/pages/nft-marketplace-development-company.js" /* webpackChunkName: "component---src-pages-nft-marketplace-development-company-js" */),
  "component---src-pages-p-2-p-crypto-exchange-development-js": () => import("./../../../src/pages/p2p-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-p-2-p-crypto-exchange-development-js" */),
  "component---src-pages-poc-development-company-js": () => import("./../../../src/pages/poc-development-company.js" /* webpackChunkName: "component---src-pages-poc-development-company-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-smart-contract-development-company-js": () => import("./../../../src/pages/smart-contract-development-company.js" /* webpackChunkName: "component---src-pages-smart-contract-development-company-js" */),
  "component---src-pages-stock-trading-app-development-company-js": () => import("./../../../src/pages/stock-trading-app-development-company.js" /* webpackChunkName: "component---src-pages-stock-trading-app-development-company-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */),
  "component---src-pages-ton-wallet-development-company-js": () => import("./../../../src/pages/ton-wallet-development-company.js" /* webpackChunkName: "component---src-pages-ton-wallet-development-company-js" */),
  "component---src-pages-wazirx-clone-script-js": () => import("./../../../src/pages/wazirx-clone-script.js" /* webpackChunkName: "component---src-pages-wazirx-clone-script-js" */),
  "component---src-pages-web-3-development-company-js": () => import("./../../../src/pages/web3-development-company.js" /* webpackChunkName: "component---src-pages-web-3-development-company-js" */),
  "component---src-pages-web-3-smart-contract-development-company-js": () => import("./../../../src/pages/web3-smart-contract-development-company.js" /* webpackChunkName: "component---src-pages-web-3-smart-contract-development-company-js" */),
  "component---src-pages-white-label-crypto-exchange-development-js": () => import("./../../../src/pages/white-label-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-white-label-crypto-exchange-development-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

